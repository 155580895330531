const allowedFocusAreas = [
  '638f829cd6fed3fc9e601187',
  '638f82d8d6fed3fc9e601197',
  '638f82c5d6fed3fc9e601193',
  '638f82b2d6fed3fc9e60118f',
  '638f82e9d6fed3fc9e60119b',
  '638f8313d6fed3fc9e6011a6',
  '6290f9caff78987947e54bcd',
  '6290f916ff78987947e54bcb',
  '6290f33fff78987947e54bc9',
  '6290f8abff78987947e54bca',
  '6290e1a7ff78987947e54bc3',
  '6290f950ff78987947e54bcc'
];

export default allowedFocusAreas;

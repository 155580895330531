import React, { useContext, useEffect, useState } from 'react';
import { orderBy, size } from 'lodash';
import { useSelector } from 'react-redux';
import NoData from 'components/NoData/NoData';
import Coachinfo from './components/Coachinfo';
import SessionItem from './components/Session_item';
import MySessionsContext from './context/MySessionsContext';
import Session from './components/Session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import { useUserUtilities } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import SessionContainer from './components/SessionContainer';
import { AiOutlineClockCircle } from 'react-icons/ai';

function MySessions() {
  const user = useSelector((state) => state.user);
  const { coach, cohort, additionalSessions } = user;
  const { selectedSession } = useContext(MySessionsContext);
  const { sessions: rawSessions } = useSelector((state) => state.user);
  const { refreshSessions } = useUserUtilities(user);
  const [orderedSessions, setOrderedSessions] = useState([]);
  const [completedSession, setCompletedSessions] = useState([]);
  const [nonAlignmentSessions, setNonAlignmentSessions] = useState([]);

  const { t } = useTranslation('global');

  const sessions = rawSessions?.map((session) => {
    return adaptedSession(session);
  });

  useEffect(() => {
    if (!selectedSession) refreshSessions();
  }, [selectedSession]);

  useEffect(() => {
    // Filtrar las sessions para excluir las alignmentSessions y las que no son alignmentSessions
    const nonAlignmentSessionsFiltered = sessions?.filter(
      (session) => session.type !== 'initial' && session.type !== 'final'
    );

    // Filtrar las alignmentSessions
    const alignmentSessionsFiltered = sessions?.filter(
      (session) => session.type === 'initial' || session.type === 'final'
    );

    // Ordenar las nonAlignmentSessions por fecha y asignarlas a orderedSessions
    setOrderedSessions(orderBy(nonAlignmentSessionsFiltered, 'date', 'asc'));

    // Filtrar y guardar las sessions completadas
    if (nonAlignmentSessionsFiltered?.length > 0) {
      const completedSessions = nonAlignmentSessionsFiltered?.filter(
        (session) => session.status === true
      );
      setCompletedSessions(orderBy(completedSessions, 'date', 'asc'));
    }

    // Ordenar las alignmentSessions por fecha y asignarlas a nonAlignmentSessions
    setNonAlignmentSessions(orderBy(alignmentSessionsFiltered, 'date', 'asc'));
  }, [rawSessions]);

  const coacheePromedioPrograma = (date) => {
    let days = user?.cohort?.program * 7;

    if (date && days) {
      let fechaInicial = new Date(date);
      fechaInicial.setDate(fechaInicial.getDate() + days + 14);

      return fechaInicial.toLocaleDateString();
    } else {
      console.error('Error: onboardingDate o days no están definidos.');
      return null;
    }
  };

  if (selectedSession) return <Session />;

  return (
    <div className="MySessions background">
      {coach && <Coachinfo coach={coach} />}
      <h2 className="MySessions__title">{t('pages.mySessions.title')}</h2>
      <h2 className="MySessions__title">
        {user.onboardingDate && (
          <div className="">
            {t('pages.reschedule.EstimatedDate')}
            <span style={{ color: '#ff0000' }}>
              {coacheePromedioPrograma(user.onboardingDate)}
            </span>
          </div>
        )}
      </h2>
      {size(orderedSessions) > 0 ? (
        <>
          <h3 style={{ marginTop: '0' }} className="MySessions__subtitle">
            {t('pages.mySessions.subtitle', {
              sessions: completedSession?.length,
              cohort: cohort?.program + additionalSessions
            })}
          </h3>
          <Tabs isFitted className="MyAssignations__container">
            <TabList mb="1em" className="MyAssignations__list">
              <Tab>{t('pages.mySessions.pendingSession')}</Tab>
              <Tab>{t('pages.mySessions.completedSessions')}</Tab>
              <Tab>{t('pages.mySessions.canceledSession')}</Tab>
              <Tab>{t('pages.mySessions.nonAlignmentSessions')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SessionContainer sessions={orderedSessions} />
              </TabPanel>
              <TabPanel>
                <SessionContainer isCompleted sessions={orderedSessions} />
              </TabPanel>
              <TabPanel>
                <SessionContainer isCanceled sessions={orderedSessions} />
              </TabPanel>
              <TabPanel>
                <SessionContainer alignment sessions={nonAlignmentSessions} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <NoData title={t('pages.mySessions.noData')} />
      )}
    </div>
  );
}

export default MySessions;

import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CoacheeModal from '../ModalCoachee/CoacheeModals';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UnactiveUserModal from 'common/UnactiveUserModal';

function CoacheeButtons() {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const session = useSelector((state) => state.session);
  const user = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isUnactiveModalOpen, setUnactiveModalOpen] = useState(false);

  useEffect(() => {
    if (session && session.status && !session.evaluatedByCoachee) {
      setModal(true);
    }
  }, [session]);

  const handleCalendarClick = () => {
    if (user.statusUser === false) {
      setModalMessage(
        'Tu usuario no se encuentra activo para agendar sesiones'
      );
      setUnactiveModalOpen(true);
    } else {
      navigate('/mycalendar');
    }
  };

  return (
    <>
      <CoacheeModal setModal={setModal} modal={modal} />

      <UnactiveUserModal
        content={modalMessage}
        isOpen={isUnactiveModalOpen}
        onClose={() => setUnactiveModalOpen(false)}
        title="Información"
        closeText="Cerrar"
      />

      <Button onClick={() => navigate('/mycoach')}>
        {t('pages.home.components.buttons.myCoach')}
      </Button>
      <Button onClick={handleCalendarClick}>
        {t('pages.home.components.buttons.myCalendar')}
      </Button>
      <Button onClick={() => navigate('/mysessions')}>
        {t('pages.home.components.buttons.mySessions')}
      </Button>
      <Button onClick={() => navigate('/myassignations')}>
        {t('pages.home.components.buttons.myAssignations')}
      </Button>
    </>
  );
}

export default CoacheeButtons;

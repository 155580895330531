const duration = 182;

const sources = (playUrl) => ({
  hd: {
    bitrate: 2005,
    size: 46723282,
    duration,
    format: 'mp4',
    width: 1280,
    height: 720,
    play_url: playUrl
  },
  sd: {
    bitrate: 900.49,
    size: 20633151,
    duration,
    format: 'mp4',
    width: 320,
    height: 240,
    play_url: playUrl
  }
});

const getPlayerProps = (videoUrl) => ({
  duration,
  sources: sources(videoUrl),
  src: videoUrl
});

export default getPlayerProps;

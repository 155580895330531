import React, { useContext, useEffect } from 'react';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { useSelector } from 'react-redux';
import {
  getUserCalendars,
  updateUserCalendars
} from 'services/calendar.service';
import Loading from 'components/Loading';
import { filter, groupBy, map, size } from 'lodash';
import NoData from 'components/NoData/NoData';
import userCalendars from 'adapters/userCalendars.adapter';
import { Button, Icon } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import SavedCalendar from 'pages/SuccessCalendar/components/SavedCalendar';
import displayToast from 'utilities/toast.utility';
import SuccessCalendarContext from 'pages/SuccessCalendar/context/SuccessCalendarContext';
import { useNavigate } from 'react-router-dom';
import { updateUserCalendar } from 'services/user.service';
import { useUserUtilities } from 'hooks';

function SaveCalendars() {
  const user = useSelector((state) => state.user);
  const { mongoID, providers, calendar } = user;
  const { loading, callEndpoint } = useFetchAndLoad();
  const { calendars, setCalendars } = useContext(SuccessCalendarContext);
  const { refreshUser, userUtilitiesLoading } = useUserUtilities();
  const navigate = useNavigate();

  const getCalendars = async () => {
    try {
      const response = await callEndpoint(getUserCalendars(mongoID));
      setCalendars(userCalendars(response.data));
    } catch (error) {
      displayToast('Error al obtener calendarios', 'error');
    }
  };

  const saveCalendars = async () => {
    try {
      await callEndpoint(updateUserCalendars(calendars));
      displayToast('Calendarios guardados con éxito', 'success');
      navigate('/');
    } catch (error) {
      console.log(
        '🚀 ~ file: SaveCalendars.js ~ line 35 ~ saveCalendars ~ error',
        error
      );
    }
  };

  const setPrimaryCalendar = async () => {
    try {
      console.log('Calendars', calendars);
      const filteredCalendars = filter(calendars, { is_primary: true });

      await callEndpoint(updateUserCalendar(user, filteredCalendars[0]._id));

      await refreshUser();
      displayToast('Calendario actualizado con éxito', 'success');
    } catch (error) {
      displayToast('Error creando el calendario', 'error');
    }
  };

  useEffect(() => {
    getCalendars();
  }, []);

  useEffect(() => {}, [calendars]);

  if (loading) return <Loading />;

  if (size(providers) > 0 && !calendar) {
    setPrimaryCalendar();
  }

  return (
    <div className="SaveCalendars">
      <h2 className="SaveCalendars__title">Calendarios</h2>
      <p className="SaveCalendars__subtitle">
        Estos son tus calendarios, elige cuales te gustaría usar para tu
        disponibilidad en Bonum
      </p>
      {size(calendars) > 0 ? (
        map(groupBy(calendars, 'email'), (group, index) => (
          <div className="SaveCalendars__group background" key={index}>
            <div className="SaveCalendars__group__title">
              <Icon
                as={FcGoogle}
                className="SaveCalendars__group__title_icon"
              />
              <p className="SaveCalendars__group__title_description">{index}</p>
            </div>
            {group.map((calendar) => (
              <SavedCalendar
                key={calendar.id}
                calendar={calendar}
                group={index}
              />
            ))}
          </div>
        ))
      ) : (
        <NoData title="No tienes ningun calendario" />
      )}

      {size(calendars) > 0 && (
        <Button className="Button Button--primary" onClick={saveCalendars}>
          Guardar
        </Button>
      )}
    </div>
  );
}

export default SaveCalendars;

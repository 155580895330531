import React, { useState, useEffect, useContext } from 'react';
import { Textarea, Button } from '@chakra-ui/react';
import EvaluationComponent from './components/Evaluation';
import WarningModal from './components/WarningModal';
import FinalModal from './components/FinalModal';
import { useSelector, useDispatch } from 'react-redux';
import SessionEvaluationContext from './context/SessionEvaluationContext';
import { updateEvaluationCoach } from 'services/coach.service';
import {
  getSessionByID,
  updateSession,
  updateSessionWithEvaluation
} from 'services/sessions.service';
import { updateStreaming } from 'services/streaming.service';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import { modifySession } from 'redux/slices/session';
import { useTranslation } from 'react-i18next';
import { updateEvaluationCoachee } from 'services/coachee.service';
import CoacheeAssistModal from './components/CoacheeAssistModal';
import { getProgramById } from 'services/program.service';
import { countBy, size } from 'lodash';
import FocusAreaItem from './components/FocusAreaItem';
import { useNavigate } from 'react-router-dom';

function Evaluation() {
  const { questionsCoach, questionsCoachee } = useContext(
    SessionEvaluationContext
  );
  const dispatch = useDispatch();

  const [suggestion, setSuggestion] = useState('');
  const [warningModal, setWarningModal] = useState('');
  const [finalModal, setfinalModal] = useState('');
  const { loading, callEndpoint } = useFetchAndLoad();
  const session = useSelector((state) => state.session);
  const user = useSelector((state) => state.user);
  const [questionRating, setQuestionRating] = useState('');
  const [coacheeModal, setCoacheeModal] = useState(true);
  const [focusAreas, setFocusAreas] = useState([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState([]);

  const { t } = useTranslation('global');

  const isCoach = user.role === 'coach';

  const navigate = useNavigate();

  useEffect(() => {
    const rating = questionsCoach.map((x) => x.ratingValue);
    const arrayWithCero = rating.some((x) => x === 0);
    setQuestionRating(arrayWithCero);
    getCoachingProgram();
  }, []);

  const handleServicesCoachee = async () => {
    try {
      await callEndpoint(
        updateSessionWithEvaluation({
          _id: session.id,
          ratingCoacheeSessions: questionsCoachee[0].ratingValue,
          commentCoachee: suggestion,
          evaluatedByCoachee: true
        })
      );
      if (user && user.coach) {
        const data = {
          RatingSessionsCoach: [
            { SessionId: session.id, Score: questionsCoachee[0].ratingValue }
          ],
          RatingCoach: [
            { SessionId: session.id, Score: questionsCoachee[1].ratingValue }
          ]
        };
        await callEndpoint(updateEvaluationCoach(session.coach._id, data));
      }

      if (session.callSession) {
        try {
          await callEndpoint(
            updateStreaming({
              _id: session.callSession,
              ratingCallStreamingCoachee: questionsCoachee[2].ratingValue
            })
          );
        } catch (error) {
          console.log('error al actualizar meeting');
        }
      }

      dispatch(
        modifySession(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );
      localStorage.setItem(
        'session',
        JSON.stringify(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );
      setfinalModal(
        t('pages.sessionEvaluation.thanks', {
          name: user.name
        })
      );
    } catch (error) {
      console.log(
        '🚀 ~ file: SelectedCoach.js ~ line 32 ~ handleSelectCoach ~ error',
        error
      );
    }
  };

  const handleServicesCoach = async () => {
    try {
      await callEndpoint(
        updateSession({
          _id: session.id,
          ratingCoachSessions: questionsCoach[0].ratingValue,
          commentCoach: suggestion,
          evaluatedByCoach: true,
          treatedFocusAreas: selectedFocusAreas
        })
      );

      if (session && session.coachee) {
        const data = {
          engagementCoachee: [
            ...session.coachee.engagementCoachee,
            { SessionId: session.id, Score: questionsCoach[1].ratingValue }
          ]
        };
        await callEndpoint(updateEvaluationCoachee(session.coachee._id, data));
      }

      if (session.callSession) {
        try {
          await callEndpoint(
            updateStreaming({
              _id: session.callSession,
              ratingCallStreamingCoach: questionsCoach[2].ratingValue
            })
          );
        } catch (error) {
          console.log('error al actualizar meeting score');
        }
      }

      dispatch(
        modifySession(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );
      localStorage.setItem(
        'session',
        JSON.stringify(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );

      setfinalModal(
        t('pages.sessionEvaluation.thanks', {
          name: user.name
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkforNPS();
  }, [user.sessions, session]);

  const getCoachingProgram = async () => {
    if (session?.coachee.coachingProgram) {
      const { data } = await callEndpoint(
        getProgramById(session.coachee.coachingProgram)
      );
      setFocusAreas(data.data[0].focusAreas);
    }
    return null;
  };

  const checkforNPS = async () => {
    if (isCoach) return;
    if (!session) return;

    const { data } = await callEndpoint(getSessionByID(session?._id));
    const { sessionNumber } = data.data;
    const MINIMUM_SESSIONS_FOR_NPS = user?.cohort?.program > 4;

    if (!sessionNumber) return;

    if (!MINIMUM_SESSIONS_FOR_NPS) return;

    const MID_NPS_SESSION = user?.cohort?.program / 2;
    const FINAL_NPS_SESSIONS = user?.cohort?.program - 1;

    if (session?.evaluatedByCoachee) {
      navigate('/');
    }

    if (MID_NPS_SESSION === sessionNumber) {
      navigate('/nps', { state: { mid: true, sessionId: session._id } });
    }

    if (FINAL_NPS_SESSIONS === sessionNumber) {
      navigate('/nps', { state: { final: true, sessionId: session._id } });
    }
  };

  const handleFocusAreasClick = (focusArea) => {
    const isSelected = selectedFocusAreas.includes(focusArea._id);

    if (isSelected) {
      setSelectedFocusAreas(
        selectedFocusAreas.filter((area) => area !== focusArea._id)
      );
    } else {
      if (selectedFocusAreas.length < 3) {
        setSelectedFocusAreas([...selectedFocusAreas, focusArea._id]);
      } else {
        return;
      }
    }
  };

  if (isCoach && coacheeModal)
    return (
      <div className="h-100 w-100">
        <CoacheeAssistModal
          coacheeModal={coacheeModal}
          setCoacheeModal={setCoacheeModal}
          session={session}
        />
      </div>
    );

  return (
    <div className="SessionEvaluation">
      <div className="SessionEvaluation__evaluation">
        <h2 className="SessionEvaluation__title">
          {t('pages.sessionEvaluation.title')} <br />{' '}
          {t('pages.sessionEvaluation.subtitle')}
        </h2>
        <div>
          {user?.role === 'coach'
            ? questionsCoach.map((question, index) => (
                <EvaluationComponent question={question} key={index} />
              ))
            : questionsCoachee.map((question, index) => (
                <EvaluationComponent question={question} key={index} />
              ))}
        </div>

        <div className="SessionEvaluation__cardtextarea">
          <Textarea
            name="suggest"
            placeholder={t('pages.sessionEvaluation.placeholder', {
              type: isCoach ? 'coachee' : 'coach'
            })}
            className="SessionEvaluation__textarea"
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
          />
        </div>

        {isCoach && size(focusAreas) > 0 && (
          <div className="SessionEvaluation__focusAreas">
            <p className="SessionEvaluation__focusAreas_title">
              {t('pages.sessionEvaluation.focusAreas')}
            </p>

            <div className="SessionEvaluation__focusAreas_areas">
              {focusAreas.map((area) => (
                <FocusAreaItem
                  focusArea={area}
                  selected={selectedFocusAreas.includes(area._id)}
                  onClick={handleFocusAreasClick}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <WarningModal modal={warningModal} setModal={setWarningModal} />
      <FinalModal modal={finalModal} setModal={setfinalModal} />
      <div className="SessionEvaluation__buttoncontainer">
        <Button
          className="Button Button--primary"
          mt={'1em'}
          onClick={
            user?.role === 'coach' ? handleServicesCoach : handleServicesCoachee
          }
        >
          {t('pages.sessionEvaluation.button')}
        </Button>
      </div>
    </div>
  );
}

export default Evaluation;

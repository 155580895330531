import React from 'react';
import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalContent,
  Button
} from '@chakra-ui/react';

function UnactiveUserModal({
  content,
  isOpen,
  onClose,
  title,
  children,
  modalClass,
  size = '2xl',
  scrollBehavior = 'inside',
  footer,
  closeText
}) {
  const windowWidth = window.innerWidth;

  const modalSize = windowWidth <= 600 ? 'lg' : size;

  const buttonStyle = {
    minWidth: windowWidth <= 600 ? '12rem' : '24rem',
    fontSize: windowWidth <= 600 ? '1.4rem' : 'inherit',
    padding: '0.5em 1em',
    height: '40px'
  };

  const contentStyle = {
    textAlign: 'center',
    margin: '1em 0',
    fontSize: '1.4rem', // Increased font size for content
    lineHeight: '1.5',
    color: '#333',
    fontWeight: 'normal'
  };

  const titleStyle = {
    fontSize: '1.8rem', // Increased font size for title
    textAlign: 'center',
    marginBottom: '0.5em',
    fontWeight: 'bold',
    color: '#333'
  };

  return (
    <ModalChakra
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc
      isCentered
      size={modalSize}
      className={modalClass}
      scrollBehavior={scrollBehavior}
    >
      <ModalOverlay />
      <ModalContent>
        {title && (
          <ModalHeader textAlign="center">
            <h4 style={titleStyle}>{title}</h4>
          </ModalHeader>
        )}
        <ModalBody>
          <div style={contentStyle}>{content}</div>
          {children}
        </ModalBody>

        <ModalFooter
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ textAlign: 'center', marginBottom: '1em' }}>
            {footer}
          </div>
          <Button
            className="Button Button--cancel Button--primary"
            onClick={onClose}
            style={buttonStyle}
          >
            {closeText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalChakra>
  );
}

export default UnactiveUserModal;

import React, { useState, useEffect } from 'react';
import { ScaleFade } from '@chakra-ui/react';
import Login from 'components/Auth/Login';
import ForgotPassword from 'components/Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading';
import { useNavigate } from 'react-router-dom';
import UnactiveUserModal from 'common/UnactiveUserModal';

function Auth({ loading }) {
  const { userLoading, uid } = useSelector((state) => state.user);
  const [login, setLogin] = useState(true);
  const [animate, setAnimate] = useState(true);
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [authMessage, setAuthMessage] = useState('');

  useEffect(() => {
    setAnimate(false);
    setTimeout(() => setAnimate(true), 250);

    const message = localStorage.getItem('authMessage');
    if (message) {
      setAuthMessage(message);
      setModalOpen(true);
      localStorage.removeItem('authMessage');
    }
  }, [login]);

  if (userLoading) return <Loading />;
  if (uid) navigate('/');

  return (
    <>
      <ScaleFade initialScale={0} in={animate}>
        {login ? (
          <Login setLogin={setLogin} loadingUser={loading} />
        ) : (
          <ForgotPassword setLogin={setLogin} />
        )}
      </ScaleFade>

      <UnactiveUserModal
        content={authMessage}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Información"
        closeText="Cerrar"
      />
    </>
  );
}

export default Auth;

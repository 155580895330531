import React, { useContext } from 'react';
import {
  Button,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import BackArrow from 'assets/images/icons/boton-anterior.png';
import MySessionsContext from 'pages/MySessions/context/MySessionsContext';
import TreatedSubjects from '../TreatedSubjects';
import Assignations from '../Assignations';
import { mongoDateToLongDateWithTime } from 'utilities';
import { AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

function Session() {
  const { setSelectedSession, selectedSession } = useContext(MySessionsContext);
  const isNormalSession = selectedSession?.type ? false : true;

  const { coach, date } = selectedSession;

  const { t, i18n } = useTranslation('global');

  return (
    <>
      <Button
        backgroundColor="transparent"
        width="28"
        marginTop={10}
        onClick={() => setSelectedSession(null)}
      >
        <Image src={BackArrow} />
      </Button>
      <div className="Session">
        <div className="Session__detail">
          <h3>
            {t('pages.mySessions.components.session.session')}{' '}
            {selectedSession?.number}
          </h3>
          <p>
            {mongoDateToLongDateWithTime({
              unformatedDate: date,
              language: i18n.language
            })}
          </p>

          <div className="Session__detail_coach">
            <Image
              src={coach?.urlImgCoach}
              className="Session__detail_coach_image"
            />

            <div className="Session__detail_coach_data_wrapper">
              <div className="Session__detail_coach_data">
                <AiOutlineUser />{' '}
                <p>
                  {coach.name} {coach.lastname}
                </p>
              </div>

              <div className="Session__detail_coach_data">
                <AiOutlineMail />
                <p>{coach.email}</p>
              </div>

              <div className="Session__detail_coach_data">
                <AiOutlinePhone />
                <p>{coach?.phone}</p>
              </div>
            </div>
          </div>
        </div>
        <Tabs isFitted isLazy lazyBehavior="unmount">
          <TabList mb="1em">
            <Tab>{t('pages.mySessions.components.session.points')}</Tab>
            {isNormalSession && (
              <>
                <Tab>
                  {t('pages.mySessions.components.session.assignationsP')}
                </Tab>
                <Tab>
                  {t('pages.mySessions.components.session.assignationsC')}
                </Tab>
              </>
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <TreatedSubjects />
            </TabPanel>

            {isNormalSession && (
              <TabPanel>
                <Assignations completed={false} />
              </TabPanel>
            )}

            {isNormalSession && (
              <TabPanel>
                <Assignations completed />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </div>
    </>
  );
}

export default Session;

import { useTranslation } from 'react-i18next';
import { GrLink } from 'react-icons/gr';

const MyResources = () => {
  const { t } = useTranslation('global');

  const resources = [
    {
      name: 'Initial',
      url: 'https://docs.google.com/document/d/1ZNMG2JdfSe7GCksD_-0mQuPQHKhyy2CtSuMbOk8bjW0/copy'
    },
    {
      name: 'Final',
      url: 'https://docs.google.com/document/d/1OihLaXddaeduRkjBwsz8ygaEYti1Mr42TjUt94BLMds/copy'
    },
    {
      name: 'Tutorials',
      url: 'https://www.youtube.com/playlist?list=PLG8zthmMmpOhaDhIqGSK7Dkum6aNLuHLM'
    }
  ];

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="MyCoachees background">
      <h2 className="CoachEvaluation__title">
        {t('pages.preferences.resources.title')}
      </h2>
      <div className="MyCoachees__coachee_list">
        {resources.map((evaluation, index) => (
          <div
            className="MyCoachees__coachee_list_item"
            key={index}
            onClick={() => openLink(evaluation.url)}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
          >
            <GrLink />
            <span className="MyCoachees__coachee_list_item_name">
              {t(`components.resources.${evaluation.name.toLowerCase()}`)}
            </span>
            <GrLink />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyResources;

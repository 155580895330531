import React from 'react';
import Auth from 'pages/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useMatch } from 'react-router-dom';
import Loading from 'components/Loading';
import { resetUser } from 'redux/slices/user';
import { signOut } from 'firebase/auth';
import { auth } from 'utilities/firebase.utility';

function ProtectedRoute({
  children,
  disabledToCoach = false,
  disabledToCoachee = false
}) {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const match = useMatch('/onboarding');
  const matchlanguages = useMatch('/updatelanguages');

  const dispatch = useDispatch();

  if (user.userLoading) return <Loading />;

  if (!user.uid) {
    return <Auth />;
  }

  if (user.activeUser === false) {
    dispatch(resetUser());
    signOut(auth);
    localStorage.setItem(
      'authMessage',
      'Ha pasado 1 año desde que ingresó al programa de coaching y ha expirado su tiempo para utilizar la plataforma'
    );
    return <Auth />;
  }

  if (user.suspendedByCompany === true) {
    dispatch(resetUser());
    signOut(auth);
    localStorage.setItem(
      'authMessage',
      'No tiene acceso a la plataforma porque la compañía no le otorga más acceso.'
    );
    return <Auth />;
  }

  if (!user?.onboardingCompleted && !match) {
    navigate('/onboarding');
    return;
  }
  if (
    !user?.languages &&
    user?.languages?.length < 1 &&
    user?.onboardingCompleted &&
    !matchlanguages
  ) {
    navigate('/updatelanguages');
    return;
  }
  if (user.uid && user.role === 'coach' && disabledToCoach) navigate('/');
  if (user.uid && user.role === 'coachee' && disabledToCoachee) navigate('/');

  return children;
}

export default ProtectedRoute;

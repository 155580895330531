import { useEffect, useState } from 'react';
import { Image, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ViewCoachCalendar from 'components/ViewCoachCalendar';
import { useTranslation } from 'react-i18next';
import { Rate } from 'antd';
import YouTube from 'react-youtube';
import { useFetchAndLoad } from 'hooks';
import { translateText } from 'services/coach.service';
import translateFocusArea from 'utilities/translateFocusArea.utility';

import Player from 'griffith';
import getPlayerProps from './playerProps'; // Asegúrate de importar correctamente la nueva función

function MyCoach() {
  const { coach } = useSelector((state) => state.user);
  const [viewCalendar, setViewCalendar] = useState(false);
  const { t, i18n } = useTranslation('global');
  const [translatedHowWork, setTranslatedHowWork] = useState(coach?.howWork);
  const [translatedResume, setTranslatedResume] = useState(coach?.resume);
  const [translatedFocusAreas, setTranslatedFocusAreas] = useState([]);

  const { loading, callEndpoint } = useFetchAndLoad();

  const translate = async (text) => {
    try {
      const { data } = await callEndpoint(
        translateText({
          targetLanguage: i18n.language,
          text: text
        })
      );

      return data.data;
    } catch (error) {
      console.error('Error translating text:', error);
      return '';
    }
  };

  useEffect(() => {
    const translateHowWork = async () => {
      try {
        // Realizar la traducción del campo "howWork"
        const translatedText = await translate(coach?.howWork);
        setTranslatedHowWork(translatedText);
      } catch (error) {
        console.error('Error translating "howWork":', error);
      }
    };

    translateHowWork();
  }, [coach?.howWork, i18n.language]);

  useEffect(() => {
    const translateResume = async () => {
      try {
        // Realizar la traducción del campo "resume"
        const translatedText = await translate(coach?.resume);
        setTranslatedResume(translatedText);
      } catch (error) {
        console.error('Error translating "resume":', error);
      }
    };

    translateResume();
  }, [coach?.resume, i18n.language]);

  useEffect(() => {
    const translateFocusAreas = async () => {
      try {
        const translatedAreas = await Promise.all(
          coach?.focusAreas?.map(async (area) => {
            const translatedText = await translateFocusArea(area);
            return translatedText;
          })
        );

        setTranslatedFocusAreas(translatedAreas);
      } catch (error) {
        console.error('Error translating focus areas:', error);
      }
    };

    translateFocusAreas();
  }, [coach?.focusAreas, i18n.language]);

  function obtenerVideoId(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  }

  const isMobile = window.innerWidth < 950;
  const isSmallScreen = window.innerWidth < 500;

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div className="SelectedCoach background">
      <ViewCoachCalendar
        isOpen={viewCalendar}
        onClose={() => setViewCalendar(false)}
        coach={coach}
      />
      <div className="MyCoach__info-wrapper">
        <div
          className="SelectedCoach__info"
          style={{ marginTop: '0', width: '96%' }}
        >
          <div
            className="SelectedCoach__info_photo-wrapper"
            style={{
              backgroundColor: '#e0f0ff',
              borderRadius: '1.5em',
              padding: '0.2em',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Image
              className="SelectedCoach__info_photo"
              src={coach?.urlImgCoach}
              objectFit="cover"
              style={{ padding: '1em' }}
            />
            <Button
              className="Button Button--secondary SelectedCoach__calendarbutton"
              onClick={() => setViewCalendar(true)}
            >
              {t('pages.myCoach.viewCalendar')}
            </Button>
          </div>

          <div
            className="SelectedCoach__info_data"
            style={{ marginLeft: '1em' }}
          >
            <div style={{ marginBottom: '0.7em' }}>
              <h4 className="SelectedCoach__info_data_name">
                {capitalizeFirstLetter(coach?.name)}{' '}
                {capitalizeFirstLetter(coach?.lastname)}
              </h4>
              <p className="SelectedCoach__info_data_focus">
                Superación Personal
              </p>
            </div>
            <div
              className="SelectedCoach__info_data_rating"
              style={{ marginBottom: '1.2em' }}
            >
              <Rate
                allowHalf
                disabled
                defaultValue={0}
                value={5}
                className="SelectedCoach__info_data_rating_stars"
              />
            </div>
            <p
              className="SelectedCoach__info_data_description"
              style={{ marginBottom: '1.5em' }}
            >
              {translatedResume ? translatedResume : coach?.resume}
            </p>
            <div className="SelectedCoach__info_focus-areas">
              <h4
                className="SelectedCoach__info_focus-areas_title"
                style={{ marginBottom: '0.7em' }}
              >
                {t('pages.myCoach.focusAreasTile')}
              </h4>
              <div className="SelectedCoach__info_focus-areas_areas">
                {translatedFocusAreas?.map((translatedArea) => (
                  <p
                    key={translatedArea._id}
                    className="SelectedCoach__info_focus-areas_area"
                  >
                    {translatedArea}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {coach?.urlVideoCoach && coach?.urlVideoCoach !== 'pending' ? (
        <div style={{ marginBottom: '3em', marginTop: '3em' }}>
          {coach.urlVideoCoach.startsWith(
            'https://firebasestorage.googleapis.com'
          ) ? (
            <div
              style={{
                width: isSmallScreen ? '250px' : isMobile ? '350px' : '600px',
                height: isSmallScreen ? '150px' : isMobile ? '200px' : '380px'
              }}
            >
              <Player {...getPlayerProps(coach.urlVideoCoach)} />
            </div>
          ) : (
            <YouTube
              videoId={obtenerVideoId(coach.urlVideoCoach)}
              opts={{
                width: isSmallScreen ? '250px' : isMobile ? '350px' : '600px',
                height: isSmallScreen ? '150px' : isMobile ? '200px' : '380px'
              }}
            />
          )}
        </div>
      ) : null}

      <div className="MyCoach__info-wrapper2" style={{ marginTop: '2em' }}>
        <div
          className="SelectedCoach__howWorks"
          style={{ marginLeft: '1em', width: '96%' }}
        >
          <h4 className="SelectedCoach__howWorks_title">
            {t('pages.myCoach.howWork')}
          </h4>
          <div className="SelectedCoach__howWorks_description">
            {translatedHowWork ? translatedHowWork : coach?.howWork}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCoach;

import React, { useState, useEffect } from 'react';
import { Image, Button } from '@chakra-ui/react';
import ArrowBack from 'assets/images/icons/boton-anterior.png';
import { useDispatch, useSelector } from 'react-redux';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { updateCoacheeOnboarding } from 'services/coachee.service';
import Congratulations from '../Congratulations';
import ViewCoachCalendar from 'components/ViewCoachCalendar';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import { Rate } from 'antd';
import { io, Socket } from 'socket.io-client';
import { modifyUser } from 'redux/slices/user';
import { translateText } from 'services/coach.service';
import YouTube from 'react-youtube';
import { Player } from 'video-react';
import translateFocusArea from 'utilities/translateFocusArea.utility';

let socket: Socket<any> = null;

function SelectedCoach(props) {
  const { coach, setSelectedCoach, setShowArrows } = props;
  const onboarding = useSelector((state: any) => state.onboarding);
  const user = useSelector((state: any) => state.user);
  const [isMyCoach, setIsMyCoach] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [viewCalendar, setViewCalendar] = useState(false);
  const { t, i18n } = useTranslation('global');
  const dispatch = useDispatch();
  const [translatedResume, setTranslatedResume] = useState('');
  const [translatedHowWork, setTranslatedHowWork] = useState('');

  const [translatedFocusAreas, setTranslatedFocusAreas] = useState([]);

  useEffect(() => {
    setShowArrows(false);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 200);

    socket = io(process.env.REACT_APP_USER_URL, {
      transports: ['websocket'],
      path: '/user',
      forceNew: true
    });

    // Traduce el contenido de coach.resume y coach.howWork cuando el componente se monta
    translate(coach.resume).then((translatedResume) => {
      translate(coach.howWork).then((translatedHowWork) => {
        setTranslatedResume(translatedResume);
        setTranslatedHowWork(translatedHowWork);
      });
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
      setShowArrows(true);
    };
  }, []);

  const translate = async (text) => {
    try {
      const { data } = await callEndpoint(
        translateText({
          targetLanguage: i18n.language,
          text: text
        })
      );

      return data.data;
    } catch (error) {
      console.error('Error translating text:', error);
      return '';
    }
  };

  const handleSelectCoach = async () => {
    try {
      const { data } = await callEndpoint(
        updateCoacheeOnboarding(onboarding, { ...user, coach })
      );
      if (data && data.data) {
        dispatch(modifyUser({ ...data.data }));
        socket.emit('set-update-user', {
          room: coach.firebaseUID,
          coachee: data.data,
          coach
        });
      }

      setIsMyCoach(true);
    } catch (error) {
      displayToast('Error al guardar tu información', 'error');
    }
  };

  function obtenerVideoId(url) {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  }

  const isMobile = window.innerWidth < 950;
  const isSmallScreen = window.innerWidth < 500;

  useEffect(() => {
    const translateFocusAreas = async () => {
      try {
        const translatedAreas = await Promise.all(
          coach?.focusAreas?.map(async (area) => {
            const translatedText = await translateFocusArea(area);
            return translatedText;
          })
        );

        setTranslatedFocusAreas(translatedAreas);
      } catch (error) {
        console.error('Error translating focus areas:', error);
      }
    };

    translateFocusAreas();
  }, [coach?.focusAreas, i18n.language]);

  return !isMyCoach ? (
    <>
      <Image
        src={ArrowBack}
        className="SelectedCoach__back"
        onClick={() => setSelectedCoach(null)}
      />
      <div className="SelectedCoach">
        <ViewCoachCalendar
          isOpen={viewCalendar}
          onClose={() => setViewCalendar(false)}
          coach={coach}
        />
        <div className="SelectedCoach__info-wrapper">
          <div className="SelectedCoach__info">
            <div
              className="SelectedCoach__info-photo-wrapper"
              style={{
                backgroundColor: '#e0f0ff',
                borderRadius: '1.5em',
                padding: '0.2em',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
              }}
            >
              <Image
                className="SelectedCoach__info_photo"
                src={coach.urlImgCoach}
                objectFit="cover"
              />
              <Button
                className="Button Button--secondary SelectedCoach__calendarbutton"
                onClick={() => setViewCalendar(true)}
              >
                {t('pages.onboarding.components.selectedCoach.calendar')}
              </Button>
            </div>
            <div className="SelectedCoach__info_data">
              <header className="SelectedCoach__info_header">
                <div className="SelectedCoach__info_header_column">
                  <h4 className="SelectedCoach__info_data_name">
                    {coach.name} {coach.lastname}
                  </h4>
                  <p className="SelectedCoach__info_data_focus">
                    Superación Personal
                  </p>
                  <div className="SelectedCoach__info_data_rating">
                    <Rate
                      allowHalf
                      disabled
                      defaultValue={0}
                      value={5}
                      className="SelectedCoach__info_data_rating_stars"
                    />
                  </div>
                </div>
                <div className="SelectedCoach__info_header_column SelectedCoach__info_header_column--button">
                  <Button
                    onClick={handleSelectCoach}
                    className="SelectedCoach__selectCoach--superior Button Button--primary"
                    disabled={loading}
                    isLoading={loading}
                  >
                    {t(
                      'pages.onboarding.components.selectedCoach.buttonSelect'
                    )}
                  </Button>
                </div>
              </header>
              <p className="SelectedCoach__info_data_description">
                {translatedResume ? translatedResume : ''}
              </p>
              <div className="SelectedCoach__info_focus-areas">
                <h4 className="SelectedCoach__info_focus-areas_title">
                  {t('pages.onboarding.components.selectedCoach.focusArea')}
                </h4>
                <div className="SelectedCoach__info_focus-areas_areas">
                  {translatedFocusAreas?.map((translatedArea) => (
                    <p
                      key={translatedArea._id}
                      className="SelectedCoach__info_focus-areas_area"
                    >
                      {translatedArea}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="SelectedCoach__info-wrapper2">
          {coach?.urlVideoCoach && coach.urlVideoCoach !== 'pending' ? (
            <div className="SelectedCoach__video-wrapper">
              {coach.urlVideoCoach.startsWith(
                'https://firebasestorage.googleapis.com'
              ) ? (
                <Player className="SelectedCoach__video">
                  <source src={coach.urlVideoCoach} />
                </Player>
              ) : (
                <YouTube
                  videoId={obtenerVideoId(coach.urlVideoCoach)}
                  opts={{
                    width: '100%',
                    height: '100%'
                  }}
                  className="SelectedCoach__video"
                />
              )}
            </div>
          ) : null}

          <div className="SelectedCoach__disclaimerVideo">
            <h5 className="SelectedCoach__disclaimerVideo_title">
              {t(
                'pages.onboarding.components.selectedCoach.disclaimerVideoInstructions'
              )}
            </h5>
            <div className="SelectedCoach__disclaimerVideo_description">
              {t(
                'pages.onboarding.components.selectedCoach.disclaimerVideoInstructions2'
              )}
            </div>
          </div>

          <div className="SelectedCoach__disclaimerVideo">
            <h5 className="SelectedCoach__disclaimerVideo_title">
              {t(
                'pages.onboarding.components.selectedCoach.disclaimerVideoNote'
              )}
            </h5>
            <div
              style={{ marginBottom: '10px' }}
              className="SelectedCoach__disclaimerVideo_description"
            >
              {t(
                'pages.onboarding.components.selectedCoach.disclaimerVideoNote2'
              )}
            </div>
          </div>
        </div>

        <div className="SelectedCoach__info-wrapper2">
          <div className="SelectedCoach__howWorks">
            <h4 className="SelectedCoach__howWorks_title">
              {t('pages.onboarding.components.selectedCoach.howWork')}
            </h4>
            <div className="SelectedCoach__howWorks_description">
              {translatedHowWork ? translatedHowWork : ''}
            </div>
          </div>
        </div>
      </div>

      <Button
        onClick={handleSelectCoach}
        className="SelectedCoach__selectCoach Button Button--primary"
        disabled={loading}
        isLoading={loading}
      >
        {t('pages.onboarding.components.selectedCoach.buttonSelect')}
      </Button>
    </>
  ) : (
    <Congratulations coach={coach} />
  );
}

export default SelectedCoach;

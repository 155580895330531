import LanguageSelector from 'components/LanguageSelector';
import bonumLogo from '../../assets/images/icons/Bonum_icon.png';
import { useTranslation } from 'react-i18next';

function Footer() {
  const year = new Date().getFullYear();

  const { t } = useTranslation('global');

  return (
    <footer className="Footer">
      <div className="Footer__info">
        <div className="Footer__branding">
          <img src={bonumLogo} alt="Logo Bonum" className="Footer__logo" />
          <p className="Footer__rights">
            &copy; {year} {t('pages.footer.rightsReserved')}
          </p>
        </div>
        <div className="Footer__links">
          <a href="https://app.bonumcoaching.com/privacy-policy">
            {t('pages.footer.privacyPolicy')}
          </a>
          <a href="https://app.bonumcoaching.com/terms-and-conditions">
            {t('pages.footer.termsAndConditions')}
          </a>
        </div>
      </div>

      <div className="Footer__translations">
        <span className="Footer__translations-text">
          {t('pages.footer.googleT')}
        </span>
        <LanguageSelector />
      </div>
    </footer>
  );
}

export default Footer;

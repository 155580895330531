import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Home from 'pages/Home';
import { auth } from 'utilities/firebase.utility';
import { onAuthStateChanged } from 'firebase/auth';
import { modifyUser, resetUser, setLoadingUser } from 'redux/slices/user';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import { getUser, updateStatusUser } from 'services/user.service';
import userAdapter from 'adapters/user.adapter';
import ProtectedRoute from 'components/ProtectedRoute';
import BasicLayout from 'layouts/BasicLayout';
import Meeting from 'pages/Meeting';
import InitMeeting from 'components/InitMeeting';
import {
  CoachCalendar,
  Preferences,
  MyCoachees,
  MyEvaluations,
  MySessions,
  MyAssignations,
  Onboarding,
  ScheduleAppointment,
  SuccessCalendar,
  ConnectCalendar,
  AutoEvaluation,
  CoacheeCalendar,
  Evaluation360,
  MyCoach,
  AlternateCall as AlternateCallModals,
  NoAuthHome,
  Auth,
  UpdateLanguages,
  PrivacyPolicy
} from 'pages';
import {
  Calendars,
  Profile,
  AlternateCall,
  AboutYouCoach,
  PickTimezone,
  Video
} from 'pages/Preferences/components';
import Coachee from 'pages/MyCoachees/components/Coachee';
import { find, size } from 'lodash';
import SessionEvaluation from 'pages/SessionEvaluation';
import { modifySession } from 'redux/slices/session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';
import OneSignal from 'react-onesignal';
import CoachEvaluation from 'pages/CoachEvaluation';
import CoacheeModal from 'pages/Home/Components/ModalCoachee';
import RescheduleAppointment from 'pages/RescheduleAppointment';
import DetailsEvaluation from 'pages/CoachEvaluation/components/DetailsEvaluation';
import EvaluationRate from 'pages/CoachEvaluation/components/Evaluation/EvaluationRate';
import { useUserUtilities } from 'hooks';
import moment from 'moment';
import MyResources from 'pages/MyResources';
import EventsCalendar from 'pages/Preferences/components/EventsCalendar';
import UnprotectedEvaluations from 'pages/UnprotectedEvaluations';
import Nps from 'pages/Nps';
import { TermsAndConditions } from 'pages/TermsAndConditions';

function App() {
  const user = useSelector((state: any) => state.user);
  const { refreshSessions } = useUserUtilities();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [
    isSessionToEvaluateMessageDisplayed,
    setIsSessionToEvaluateMessageDisplayed
  ] = useState(false);

  const getUserApi = async (id) => {
    try {
      const userData = await callEndpoint(getUser(id));
      return userData.data;
    } catch (error) {
      return false;
    }
  };

  const initOneSignalConfig = async () => {
    await OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APPID
    });

    await OneSignal.showSlidedownPrompt();
  };

  const getUserSessionsToEvaluate = async () => {
    if (user?.sessions && user?.sessions?.length > 0) {
      let sessionsToEvaluate = null;

      if (user.role === 'coach') {
        sessionsToEvaluate = find(user.sessions, {
          evaluatedByCoach: false,
          status: true
        });
      } else {
        sessionsToEvaluate = find(user.sessions, {
          evaluatedByCoachee: false,
          status: true
        });
      }

      if (
        size(sessionsToEvaluate) > 0 &&
        !isSessionToEvaluateMessageDisplayed &&
        user.role !== 'coach'
      ) {
        setModal(true);
        setIsSessionToEvaluateMessageDisplayed(true);
      }
    }
  };

  const getSessions = async () => {
    try {
      await refreshSessions();
    } catch (error) {
      console.log('🚀 ~ file: App.tsx:114 ~ getSessions ~ error:', error);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      initOneSignalConfig();
      if (firebaseUser) {
        dispatch(setLoadingUser(true));
        const userData = await getUserApi(firebaseUser.uid);
        if (userData) {
          OneSignal.setExternalUserId(userData.data._id);
          dispatch(modifyUser(userAdapter(userData)));
        }
        dispatch(setLoadingUser(false));
        dispatch(
          modifySession(
            adaptedSession(JSON.parse(localStorage.getItem('session')))
          )
        );
      } else {
        dispatch(resetUser());
        setIsSessionToEvaluateMessageDisplayed(false);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getUserSessionsToEvaluate();
  }, [user]);

  useEffect(() => {
    if (user.mongoID) {
      getSessions();
    }
  }, [user.mongoID]);

  const checkAndUpdateUserStatus = async () => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const userCreatedAt = new Date(user.createdAt);

    if (userCreatedAt < oneYearAgo) {
      try {
        await callEndpoint(updateStatusUser(user.mongoID));
      } catch (error) {
        return;
      }
    }
  };

  useEffect(() => {
    checkAndUpdateUserStatus();
  }, [user.mongoID, user]);

  const location = useLocation();

  useEffect(() => {
    console.log('Current path:', location.pathname);
  }, [location]);

  return (
    <BasicLayout>
      {user.activeUser === true &&
        user.statusUser === true &&
        user.suspendedByCompany === false && (
          <CoacheeModal
            message={
              'Hay sesiones sin evaluar. Por favor evalua las sesiones sin evaluar.'
            }
            pathTo={'/mysessions'}
            setModal={setModal}
            modal={modal}
          />
        )}

      <AlternateCallModals user={user} />

      <Routes>
        <Route path="/" element={user?.uid ? <Home /> : <NoAuthHome />} />
        <Route
          path="/onboarding"
          element={
            <ProtectedRoute>
              <Onboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/schedule"
          element={
            <ProtectedRoute disabledToCoach>
              <ScheduleAppointment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mycalendar"
          element={
            <ProtectedRoute>
              {user?.role === 'coach' ? (
                <CoachCalendar />
              ) : (
                user.statusUser && <CoacheeCalendar />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="/myresources"
          element={
            <ProtectedRoute>
              {user?.role === 'coach' && <MyResources />}
            </ProtectedRoute>
          }
        />

        <Route
          path="/preferences"
          element={
            <ProtectedRoute>
              <Preferences />
            </ProtectedRoute>
          }
        >
          <Route index={true} element={<Profile />} />
          <Route
            path="calendar"
            element={
              <ProtectedRoute disabledToCoachee>
                <Calendars />
              </ProtectedRoute>
            }
          />

          <Route
            path="alternatecall"
            element={
              <ProtectedRoute disabledToCoachee>
                <AlternateCall />
              </ProtectedRoute>
            }
          />

          <Route
            path="work"
            element={
              <ProtectedRoute disabledToCoachee>
                <AboutYouCoach />
              </ProtectedRoute>
            }
          />

          <Route
            path="timezone"
            element={
              <ProtectedRoute>
                <PickTimezone />
              </ProtectedRoute>
            }
          />

          <Route
            path="video"
            element={
              <ProtectedRoute>
                <Video />
              </ProtectedRoute>
            }
          />

          <Route
            path="eventscalendar"
            element={
              <ProtectedRoute>
                <EventsCalendar />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/mycoachees"
          element={
            <ProtectedRoute disabledToCoachee>
              <MyCoachees />
            </ProtectedRoute>
          }
        >
          <Route path=":userId" element={<Coachee />}>
            <Route path=":sessionId" element={<Coachee />} />
          </Route>
        </Route>

        <Route
          path="/myevaluations"
          element={
            <ProtectedRoute>
              {user?.role === 'coachee' ? (
                <MyEvaluations />
              ) : (
                <CoachEvaluation />
              )}
            </ProtectedRoute>
          }
        >
          <Route index={true} element={<EvaluationRate />} />
          <Route
            path="sessions"
            element={<DetailsEvaluation isSession={true} />}
          />
          <Route
            path="coach"
            element={<DetailsEvaluation isSession={false} />}
          />
        </Route>

        <Route
          path="/init-meeting"
          element={
            <ProtectedRoute>
              <InitMeeting />
            </ProtectedRoute>
          }
        />

        <Route
          path="/meeting/:id"
          element={
            <ProtectedRoute>
              <Meeting />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mysessions"
          element={
            <ProtectedRoute disabledToCoach>
              <MySessions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/myassignations"
          element={
            <ProtectedRoute disabledToCoach>
              <MyAssignations />
            </ProtectedRoute>
          }
        />

        <Route
          path="/successcalendar"
          element={
            <ProtectedRoute disabledToCoachee>
              <SuccessCalendar />
            </ProtectedRoute>
          }
        />

        <Route
          path="/connectcalendar"
          element={
            <ProtectedRoute disabledToCoachee>
              <ConnectCalendar />
            </ProtectedRoute>
          }
        />

        <Route path="evaluation360" element={<Evaluation360 />}>
          <Route path=":id_evaluation360" element={<Evaluation360 />} />
        </Route>

        <Route path="autoevaluation" element={<AutoEvaluation />}>
          <Route path=":id_autoevaluation" element={<AutoEvaluation />} />
        </Route>

        <Route
          path="/mycoach"
          element={
            <ProtectedRoute disabledToCoach>
              <MyCoach />
            </ProtectedRoute>
          }
        />

        <Route
          path="reschedule"
          element={
            <ProtectedRoute>
              <RescheduleAppointment />
            </ProtectedRoute>
          }
        >
          <Route
            path=":sessionId"
            element={
              <ProtectedRoute>
                <RescheduleAppointment />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/sessionevaluation"
          element={
            <ProtectedRoute>
              <SessionEvaluation />
            </ProtectedRoute>
          }
        />

        <Route
          path="/login"
          element={
            <ProtectedRoute>
              <Auth loading={loading} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/updatelanguages"
          element={
            <ProtectedRoute>
              <UpdateLanguages />
            </ProtectedRoute>
          }
        />

        <Route
          path="/evaluationResults/:id"
          element={<UnprotectedEvaluations />}
        />

        <Route
          path="/nps"
          element={
            <ProtectedRoute>
              <Nps />
            </ProtectedRoute>
          }
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BasicLayout>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import Assignations from './components/Assignations';
import { useFetchAndLoad } from 'hooks';
import {
  editAssignation,
  getUserAssignations
} from 'services/assignations.service';
import { useSelector } from 'react-redux';
import assignationsAdapted from 'adapters/assignations.adapter';
import displayToast from 'utilities/toast.utility';
import { useTranslation } from 'react-i18next';
import TaskCompletionAlert from 'common/Alert';

function MyAssignations() {
  const { loading, callEndpoint } = useFetchAndLoad();
  const { mongoID } = useSelector((state) => state.user);
  const [assignations, setAssignations] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // Estado para mostrar el alert

  const { t } = useTranslation('global');

  const getError = t('pages.myAssignations.getError');

  const getMyAssignations = async () => {
    try {
      const { data } = await callEndpoint(getUserAssignations(mongoID));
      setAssignations(
        data.data.map((assignation) => assignationsAdapted(assignation))
      );
    } catch (error) {
      console.log(error);
      displayToast(getError, 'error');
    }
  };

  useEffect(() => {
    getMyAssignations();
  }, []);

  const errorChange = t('pages.myAssignations.errorChange');

  const handleChange = async (changedAssignation) => {
    try {
      await callEndpoint(
        editAssignation({
          ...changedAssignation,
          status: !changedAssignation.status
        })
      );

      setAssignations(
        assignations.map((assignation) => {
          if (assignation.id !== changedAssignation.id) return assignation;
          return { ...changedAssignation, status: !changedAssignation.status };
        })
      );

      // Mostrar alert si la tarea se marca como completada
      if (!changedAssignation.status) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000); // Ocultar el alert después de 3 segundos
      }
    } catch (error) {
      displayToast(errorChange, 'error');
    }
  };

  return (
    <>
      {/* {showAlert && (
        <div className="fixed top-0 left-0 w-full z-50 flex justify-center mt-4">
          <TaskCompletionAlert
            message="Tarea completada con éxito"
            onClose={() => setShowAlert(false)}
          />
        </div>
      )} */}
      <Tabs isFitted className="MyAssignations__container">
        <TabList mb="1em" className="MyAssignations__list">
          <Tab>{t('pages.myAssignations.tabPendings')}</Tab>
          <Tab>{t('pages.myAssignations.tabComplete')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Assignations
              assignations={assignations}
              completed={false}
              setAssignations={setAssignations}
              handleChange={handleChange}
            />
          </TabPanel>
          <TabPanel>
            <Assignations
              completed
              assignations={assignations}
              setAssignations={setAssignations}
              handleChange={handleChange}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}

export default MyAssignations;

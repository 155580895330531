import { useTranslation } from 'react-i18next';
import OpenQuestion from '../OpenQuestion';
import Rating from '../Rating';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useFetchAndLoad, useUserUtilities } from 'hooks';
import displayToast from 'utilities/toast.utility';
import { createMidNPS } from 'services/nps.service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateSession } from 'services/sessions.service';
import { modifySession } from 'redux/slices/session';
import adaptedSession from 'adapters/sessionsAdapter.adapter';

const MidNps = ({ session }) => {
  const { t } = useTranslation('global');
  const [recomendation, setRecomendation] = useState(0);
  const [usefulAspect, setUsefulAspect] = useState('');
  const [obstacles, setObstacles] = useState('');
  const { mongoID } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, callEndpoint } = useFetchAndLoad();

  const handleClick = async () => {
    try {
      await callEndpoint(
        createMidNPS({
          coachee: mongoID,
          obstacles,
          recomendation,
          usefulAspect
        })
      );

      await callEndpoint(
        updateSession({
          _id: session,
          evaluatedByCoachee: true
        })
      );

      dispatch(
        modifySession(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );
      localStorage.setItem(
        'session',
        JSON.stringify(
          adaptedSession({ ...session, status: true, evaluatedByCoachee: true })
        )
      );

      navigate('/');
      displayToast('Encuesta guardada con éxito', 'success');
    } catch (error) {
      displayToast(`Error creating NPS: ${error}`, 'error');
    }
  };

  return (
    <div>
      <Rating
        question={t('pages.midnps.recomendation')}
        starsNumber={10}
        value={recomendation}
        setValue={setRecomendation}
      />
      <OpenQuestion
        question={t('pages.midnps.usefulAspect')}
        value={usefulAspect}
        setValue={setUsefulAspect}
      />
      <OpenQuestion
        question={t('pages.midnps.obstacles')}
        value={obstacles}
        setValue={setObstacles}
      />

      <div className="center--button">
        <Button
          className="Button Button--primary"
          mt={'1em'}
          textAlign={'center'}
          onClick={handleClick}
          isLoading={loading}
          disabled={loading}
        >
          {t('pages.sessionEvaluation.button')}
        </Button>
      </div>
    </div>
  );
};

export default MidNps;
